/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

$(async function () {
	// Slideshow
	$('.swiper-container.hero').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		new Swiper(this, {
			grabCursor: true,
			loop: true,
			effect: "fade",
			autoplay: {
				delay: 3000
			},
			fadeEffect: {
				crossFade: true
			},
			speed: 1500,
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + ($(this.slides[index + 1]).find('.title').text()) + '</span>';
				}
			}
		});
	});

	$('.slideshow .swiper-container').each(async function () {
		const Swiper = (await import('swiper/dist/js/swiper.js')).default;

		new Swiper(this, {
			grabCursor: true,
			loop: true,
			effect: "fade",
			autoplay: {
				delay: 3000
			},
			speed: 1500,
			pagination: {
				el: '.slideshow-swiper-pagination',
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + ($(this.slides[index + 1]).data('title')) + '</span>';
				}
			}
		});
	});

	async function matchHeights() {
		await import('jquery-match-height');

		$('.match-height').matchHeight({
			property: 'height'
		});
	};

	if ($('.match-height')[0]) {
		matchHeights();
	}

	// Google Maps 
	$('.google-maps').each(async function () {
		var maps = $(this);
		var apiKey = maps.data('api-key');
		var dataGeolocation = maps.data('geolocation').split(',');

		const loadGoogleMapsApi = (await import('load-google-maps-api')).default;
		const googleMaps = await loadGoogleMapsApi({
			key: apiKey
		});

		var myLatLng = {
			lat: parseFloat(dataGeolocation[0]),
			lng: parseFloat(dataGeolocation[1])
		};

		var map = new googleMaps.Map(document.querySelector('.google-maps'), {
			center: myLatLng,
			zoom: 13,
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			styles: [{ "featureType": "all", "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#000000" }, { "lightness": 40 }] }, { "featureType": "all", "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#000000" }, { "lightness": 16 }] }, { "featureType": "all", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#000000" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#000000" }, { "lightness": 17 }, { "weight": 1.2 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#000000" }, { "lightness": 20 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#000000" }, { "lightness": 21 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#000000" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#000000" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#000000" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#000000" }, { "lightness": 16 }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#000000" }, { "lightness": 19 }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#000000" }, { "lightness": 17 }] }]
		});

		var imageUrl = '/images/pointer.png';

		var image = {
			url: imageUrl,
			scaledSize: new google.maps.Size(60, 75),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(30, 75)
		}

		var marker = new google.maps.Marker({
			position: myLatLng,
			map: map,
			icon: image
		});
	});

	// Responsive menu
	$('.toggler').each(function () {
		$(this).click(toggleMenu);
	});

	function toggleMenu() {
		var toggler = $('.toggler')
		var responsiveNav = $('.responsive-nav');

		$(document.body).toggleClass('menu-open');
		responsiveNav.toggleClass('-collapsed');
		toggler.toggleClass('is-active');
	}

	// Update forms
	var updateForms = function (element) {
		var $element = $(element);

		// Form focus
		$element.find('.umbraco-forms-field :input').each(function () {
			var $this = $(this),
				field = $this.parent('.umbraco-forms-field');

			$this.on({
				change: function () {
					if ($this.val().length) {
						field.addClass('has-value');
					} else {
						field.removeClass('has-value');
					}
				},
				focus: function () {
					field.addClass('focus');
				},
				blur: function () {
					field.removeClass('focus');
				}
			}).trigger('change');
		});

		// Jump to submitted form
		$('.contact-form:has(form)').each(function () {
			var $this = $(this),
				$form = $this.find('form'),
				anchor = $this.find('.anchor').attr('name');

			$form.attr('action', $form.attr('action') + '#' + anchor);
		});
	};
	updateForms(document);
});